<template>
    <div>
        <div class="box p-6 has-text-centered">
            PROCESSING SUBSCRIPTION PLEASE WAIT...
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {

    created() {
        this.$store.dispatch('billing/verifyNewCard', {
            transactionId: this.$route.query.id
        }).then(data => {
            this.$store.commit('auth/setCard', data.card)
            this.$store.dispatch('billing/activateAndBillForSubscription', data).then(() => {
                this.activating = false
                this.$store.dispatch('billing/loadSubscription')
                this.$toast.success('Your Subscription has been activated.')
                this.$router.push({
                    name: 'dashboard'
                })
            }).catch(() => this.activating = false)
        }, error => {
            this.$toast.error(error.response.data)
        })
    },

    computed: {
        ...mapGetters('billing', [
            'subscription',
            'subscription_checkout'
        ]),
    }
}
</script>